<template>
  <div class="row">
    <div class="col-12">
      <ibox
        :title="'Podstrony LP EDU'"
        :description="
          `Edycja umożliwia zarządzanie treścią na ${this.$route.params.shop_name}.${this.$route.params.shop_lang}`
        "
      >
        <template slot="content" slot-scope="{ setLoading }">
          <DataTable
            v-if="show"
            :manualColumns="manualColumns"
            :url="url"
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "BestsellerSaleStatistics",
  components: {
    Ibox,
    DataTable,
  },
  data() {
    return {
      show: true,
      manualColumns: [
        {
          key: "id",
          name: "ID",
          show: true,
          type: "",
          filter: "",
        },
        {
          key: "lang",
          name: "Język",
          show: true,
          type: "",
          filter: "",
        },
        {
          key: "sub",
          name: "Tytuł",
          show: true,
          type: "",
          filter: "",
        },
        {
          key: "database",
          name: "Sklep",
          show: true,
          type: "",
          filter: "",
        },
        {
          key: "url",
          name: "Strona WWW",
          show: true,
          type: "",
          filter: "",
        },
      ],
    };
  },
  computed: {
    url() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lp-edu/list`;
    },
  },
};
</script>
